import React from 'react'
import { Box, Flex } from 'theme-ui'

import Text from '~/components/IntlComponents/Text'
import PropTypes from 'prop-types'
import CheckIcon from '~/assets/images/icons/check-mini.svg'

const Label = ({ name, selected, selectionMade }) => {
  const shakeAnimationKeyframes = {
    '@keyframes shake': {
      '0%': {
        transform: 'translate(-50%, 0) rotate(0deg)',
      },
      '10%': {
        transform: 'translate(-50%, 0) rotate(3deg)',
      },
      '20%': {
        transform: 'translate(-50%, 0) rotate(-3deg)',
      },
      '30%': {
        transform: 'translate(-50%, 0) rotate(3deg)',
      },
      '50%': {
        transform: 'translate(-50%, 0) rotate(0deg)',
      },
      '100%': {
        transform: 'translate(-50%, 0) rotate(0deg)',
      },
    },
  }

  const labelStyle = {
    backgroundColor: selected ? 'blue' : 'yellow',
    color: selected ? 'yellow' : 'blue',
    p: '6px 10px 6px 14px',
    position: 'absolute',
    bottom: 30,
    zIndex: 1,
    left: '50%',
    transform: 'translate(-50%, 0) rotate(0deg)',
    borderRadius: 100,
    alignItems: 'center',
    border: '2px solid',
    borderColor: selected ? 'yellow' : 'transparent',
    animation: selectionMade ? 'none' : '3s infinite shake',
    minWidth: 180,
    '&:hover': {
      backgroundColor: 'blue',
      color: 'yellow',
      cursor: 'pointer',

      '> .label-text': {
        color: 'yellow',
      },

      '> .label-circle': {
        bg: selected ? 'yellow' : 'blue',
        borderColor: selected ? 'none' : 'yellow',
      },
    },
    shakeAnimationKeyframes,
  }

  const circleStyle = {
    height: '28px',
    width: '28px',
    borderRadius: '50%',
    display: 'iniline-block',
    ml: '10px',
    flexShrink: 0,
    border: '2px solid blue',
    bg: selected ? 'yellow' : 'transparent',
  }

  const textStyle = {
    textTransform: 'uppercase',
    lineHeight: '18px',
    fontSize: '16px',
    color: selected ? 'yellow' : 'blue',
    flexGrow: 1,
    pt: 0.5,
  }

  return (
    <Flex sx={labelStyle}>
      <Text sx={textStyle} className="label-text">
        {name}
      </Text>
      <Box sx={circleStyle} className="label-circle">
        <CheckIcon
          style={{
            display: selected ? 'inline-block' : 'none',
          }}
        />
      </Box>
    </Flex>
  )
}

Label.propTypes = {
  name: PropTypes.string.isRequired,
  selected: PropTypes.bool,
}

export default Label

import React, { useEffect, useState } from 'react'
import {
  useCart,
  useCheckout,
  useTranslate,
} from '@chordcommerce/gatsby-theme-autonomy'
import { useColorMode, Flex } from 'theme-ui'
import PropTypes from 'prop-types'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import ConfirmationPage from '~/components/Gift/ConfirmationPage'
import Spinner from '~/components/Generic/Spinner'
import useLocaleCartLogic from '~/utils/intl/context/hooks/use-locale-cart-logic'

const Confirmation = ({ location }) => {
  const [, setColorMode] = useColorMode()
  const translate = useTranslate()
  const [cart, setCart] = useState()

  const params = new URLSearchParams(location.search)
  const orderNumber = params.get('number') || ''
  const token = params.get('token') || ''

  const getCartData = async () => {
    const response = await fetch(
      `${process.env.GATSBY_ACS_API_URL}/api/orders/${orderNumber}`,
      {
        methods: 'GET',
        headers: {
          'X-Spree-Order-Token': token,
          'Content-Type': 'application/json',
        },
      },
    )
      .then(r => r.json())
      .catch(error => {
        console.log(error)
      })
    response?.number && setCart(await response)
  }

  useEffect(() => {
    setColorMode('Gift-Flow-Form')
    getCartData()
  }, [])

  return (
    <Layout>
      <Metadata title={translate('gifting_flow.confirmation.gift_redeemed')} />
      {!cart || !cart?.line_items?.[0].variant ? (
        <Flex sx={{ justifyContent: 'center', margin: '100px 0 200px 0' }}>
          <Spinner size={100} />
        </Flex>
      ) : (
        <ConfirmationPage cart={cart} />
      )}
    </Layout>
  )
}

export default Confirmation

Confirmation.propTypes = {
  location: PropTypes.object,
}

import { useEffect, useState } from 'react'
import useProducts from '../graphql/queries/use-products'

const useGiftProductImage = ({ sku, slug }) => {
  const [image, setImage] = useState(null)
  const products = useProducts()

  useEffect(() => {
    let match = null

    if (slug.includes('variety-pack')) {
      match = products.find(product => product.slug === 'variety-pack-product')
    } else if (slug.includes('pasta-pack')) {
      match = products.find(product => product.slug === 'pasta-pack-product')
    } else {
      match = products.find(product =>
        product.variants.find(variant => variant.sku === sku),
      )
    }

    if (!match) return
    let image = null
    const variantImage = match.variants.find(variant => variant.sku === sku)
    if (variantImage) {
      image = variantImage.mainImage
    } else {
      image = match.overheadProductImage
    }
    if (image) setImage(image)
  }, [sku])

  return { image }
}

export default useGiftProductImage

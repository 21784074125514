import React, { useState } from 'react'
import { Box, Image, Flex } from 'theme-ui'

import PropTypes from 'prop-types'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import { GatsbyImage } from 'gatsby-plugin-image'
import Label from '../Label'
import useGiftProductImage from '~/hooks/components/use-gift-product-image'
import Text from '~/components/IntlComponents/Text'

const Product = ({
  imageUrl,
  name,
  optionsText,
  description,
  sku,
  alt,
  onSelection,
  selected,
  selectionMade,
  confirmed,
  sx,
  slug,
}) => {
  const translate = useTranslate()
  const [opened, setOpened] = useState(false)
  const toggleOpen = () => setOpened(open => (open = !open))

  const { image } = useGiftProductImage({ sku, slug })

  const containerStyle = {
    flexFlow: 'column wrap',
    justifyContent: 'space-between',
    height: 500,
    padding: ['50px 10px 0', '50px 20px 10px'],
    textAlign: 'center',
    ...sx,
  }

  const productStyle = {
    position: 'relative',
    flexFlow: 'column wrap',
    justifyContent: 'space-between',
    width: 310,
    fontFamily: 'Sofia',
  }

  const giftStyle = {
    position: 'relative',
    flexFlow: 'column wrap',
    justifyContent: 'space-between',
  }

  const circleStyle = {
    border: '2px solid',
    borderColor: selected ? '#FFDE00' : 'tertiary',
    backgroundColor: selected ? '#2B38BD' : 'transparent',
    width: opened ? 180 : 310,
    height: opened ? 180 : 310,
    objectFit: 'cover',
    margin: 'auto',
    borderRadius: '100%',
    zIndex: 1,
    transition: 'width 0.3s, height 0.3s, border 0.3s, backgroundColor 0.3s',
    '&:hover': {
      cursor: 'pointer',
    },
    overflow: 'hidden',
  }

  const nameStyle = {
    color: selected ? '#FFDE00' : 'tertiary',
    textTransform: 'uppercase',
    fontSize: 20,
    fontWeight: 400,
    marginTop: 10,
    '&:hover': {
      cursor: 'pointer',
    },
  }

  const optionTextStyle = {
    minHeight: '20px',
    color: selected ? '#FFDE00' : 'tertiary',
    fontSize: 16,
    fontWeight: 400,
    marginTop: 20,
    '&:hover': {
      cursor: 'pointer',
    },
  }

  const detailStyle = {
    position: 'absolute',
    opacity: opened ? 1 : 0,
    transition: 'opacity 0.3s',
    top: 260,
    width: 310,
    maxHeight: 200,
    height: 'auto',
    overflow: 'auto',
    fontFamily: 'Sofia-Regular',
    color: 'tertiary',
  }

  const moreInfoStyle = {
    color: 'secondary',
    fontSize: 16,
    fontFamily: 'Sofia',
    fontWeight: 700,
    textDecoration: 'underline',
    mt: opened ? '1rem' : 0,
    '&:hover': {
      cursor: 'pointer',
    },
  }

  const checkStyle = {
    position: 'absolute',
    opacity: selected ? 1 : 0,
    transition:
      'opacity 0.3s, right 0.3s, left 0.3s, width 0.3s, height 0.3s, lineHeight 0.3s',
    bottom: optionsText ? 80 : 50,
    right: opened ? 80 : 30,
    width: opened ? 30 : 48,
    height: opened ? 30 : 48,
    borderRadius: '100%',
    backgroundColor: '#FFDE00',
    lineHeight: opened ? 2.5 : 4.6,
    svg: {
      width: opened ? 22 : 36,
      height: opened ? 22 : 36,
      transition: 'width 0.3s, height 0.3s',
    },
    '&:hover': {
      cursor: 'pointer',
    },
    zIndex: 1,
  }

  const imageStyle = {
    height: '100%',
    objectFit: 'cover',
  }

  const imageWrapperStyle = {
    height: '100%',
  }

  return (
    <Flex sx={containerStyle}>
      <Flex sx={productStyle}>
        <Flex
          sx={giftStyle}
          onClick={() => onSelection?.(sku)}
          data-testid={`product-handler-${sku}`}
        >
          <Box sx={circleStyle}>
            {image ? (
              <GatsbyImage
                style={imageWrapperStyle}
                imgStyle={imageStyle}
                image={image.gatsbyImageData}
                alt={image.title}
              />
            ) : (
              <Image src={imageUrl} sx={imageStyle} alt={alt} />
            )}
          </Box>

          <Box>
            <Label
              name={name}
              selected={selected}
              selectionMade={selectionMade}
            />
            <Box sx={optionTextStyle}>{optionsText}</Box>
          </Box>
        </Flex>
        <Box sx={detailStyle}>
          <Text>{description}</Text>
        </Box>
      </Flex>
      {!confirmed && (
        <Text sx={moreInfoStyle} onClick={() => toggleOpen()}>
          {translate(`gifting_flow.selector.${opened ? 'less' : 'more'}_info`)}
        </Text>
      )}
    </Flex>
  )
}

Product.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  name: PropTypes.string,
  description: PropTypes.string,
  sku: PropTypes.string,
  onSelection: PropTypes.func,
  selected: PropTypes.bool,
  confirmed: PropTypes.bool,
  sx: PropTypes.shape({}),
}

export default Product

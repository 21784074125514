import React from 'react'
import PropTypes from 'prop-types'
import { Box, Flex } from 'theme-ui'

import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import Product from '../Product'
import Heading from '~/components/IntlComponents/Heading'

import Text from '~/components/IntlComponents/Text'
import ContactsWithVideo from '~/components/Product/Page/ContactsWithVideo'
import { SectionDivider } from '~/components/Nav/NavMenu/SectionDivider'

const ConfirmationPage = ({ cart }) => {
  const translate = useTranslate()
  const { line_items: lineItems, ship_address: shipAddress, number } = cart
  const { images, name, sku, slug } = lineItems[0].variant
  const { address1, city, zipcode, name: userName } = shipAddress

  const { alt: imageAlt, smallUrl } = images[0]
  const alt = imageAlt ?? `image-${sku}`

  const wrapperStyle = {
    margin: '2rem 30px 50px 0',
    alignItems: 'center',
    flexDirection: 'column',
  }

  const titleStyling = {
    color: 'secondary',
    fontFamily: 'Sofia',
    fontSize: [40, 60],
    lineHeight: 1,
    fontWeight: 700,
    textAlign: 'center',
    maxWidth: 750,
    margin: '0 auto 50px',
    textTransform: 'uppercase',
  }

  const subtitleStyling = {
    color: 'secondary',
    fontFamily: 'Sofia-Regular',
    fontSize: 20,
    lineHeight: 1,
    textAlign: 'center',
    maxWidth: 750,
    margin: '0 auto 100px',
  }

  const boxStyle = theme => ({
    ...theme.cards.primary,
    border: '1px solid',
    borderColor: 'tertiary',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    borderRadius: 8,
    width: ['100%', 750],
    height: '100vh',
    maxHeight: '600px',
    margin: 20,
    padding: 20,
  })

  const addressStyle = {
    color: 'secondary',
    textAlign: 'center',
    fontSize: 20,
  }

  return (
    <>
      <Flex sx={wrapperStyle}>
        <Heading as="h4" variant="h4" sx={titleStyling}>
          {translate('gifting_flow.confirmation.gift_redeemed')}
        </Heading>
        <Text sx={subtitleStyling}>
          {translate('gifting_flow.confirmation.gift_redeemed_subtitle')}
        </Text>
        <Text sx={{ color: 'secondary', mb: 2 }}>
          {translate('gifting_flow.confirmation.order', { number })}
        </Text>
        <Box sx={boxStyle}>
          <Product
            alt={alt}
            imageUrl={smallUrl}
            name={name}
            confirmed={true}
            sx={{ mt: -40 }}
            sku={sku}
            slug={slug}
            selectionMade={true}
            selected={true}
          />
          <Text
            sx={{
              color: 'secondary',
              fontSize: 14,
              textTransform: 'uppercase',
            }}
          >
            {translate('gifting_flow.confirmation.shipping_to')}
          </Text>
          <Text sx={addressStyle} mb={-3}>
            {userName}
          </Text>
          <Text sx={addressStyle}>{`${address1}, ${zipcode}, ${city}`}</Text>
        </Box>
      </Flex>
      <SectionDivider small="true" fill="#AEE0FD"></SectionDivider>
      <ContactsWithVideo
        headingText="We’re SO excited for you to try GOODLES! "
        containerStyle={{ color: '#6E5BE8', backgroundColor: '#AEE0FD' }}
        url="//videos.ctfassets.net/bdj7cfajz56z/3yhTEhek86ZYroH5Bdx9VL/ed5ca15a358cd185393e8e3de98bed3b/Tasting_Background_Landscape_-_HB.mp4"
      />
    </>
  )
}

export default ConfirmationPage

ConfirmationPage.propTypes = {
  cart: PropTypes.shape({
    lineItems: PropTypes.arrayOf(
      PropTypes.shape({
        variant: PropTypes.shape({
          name: PropTypes.string,
          images: PropTypes.arrayOf(
            PropTypes.shape({
              smallUrl: PropTypes.string,
              alt: PropTypes.string,
            }),
          ),
        }),
      }),
    ),
    shipAddress: PropTypes.shape({
      address1: PropTypes.string,
      city: PropTypes.string,
      zipcode: PropTypes.string,
      name: PropTypes.string,
    }),
    number: PropTypes.string,
  }).isRequired,
}
